import { createSlice } from '@reduxjs/toolkit';
import { getAllReportsForClient } from 'features/reports/clients/async';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import { ClientReportsState } from './types';

export const initialState: ClientReportsState = {
  reports: {
    data: undefined,
    request: initialRequestState,
  },
};

export const slice = createSlice({
  name: 'clientReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAllReportsForClient.fulfilled,
      (state, { meta, payload }) => {
        if (state.reports.request.id === meta.requestId) {
          state.reports.data = payload;
          state.reports.request = requestReducer(
            state.reports.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getAllReportsForClient.pending, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllReportsForClient.rejected, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        rejected({ meta })
      );
    });
  },
});

const { reducer } = slice;

export default reducer;
