import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdvertiserDto,
  AdvertiserFileDto,
  CustomReportDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getSelectedAdvertiserById = createAsyncThunk<
  AdvertiserDto,
  { clientId: number; id: number }
>(
  'advertiserById/getSelectedAdvertiserById',
  async ({ clientId, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.advertiser.getAdvertiserByClientIdAndId(
        clientId,
        id
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }
      return rejectWithValue(apiError);
    }
  }
);

export const getCustomReportsForAdvertiser = createAsyncThunk<
  CustomReportDto[],
  {
    advertiserId: number;
  }
>(
  'advertisersById/getCustomReportsForAdvertiser',
  async ({ advertiserId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.customReporting.getCustomReportsForAdvertiser(
        advertiserId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);
      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }
      return rejectWithValue(apiError);
    }
  }
);

export const getAllFilesByAdvertiserId = createAsyncThunk<
  AdvertiserFileDto[],
  { clientId: number; advertiserId: number },
  AsyncThunkConfig
>(
  'advertiser/getAdvertiserFiles',
  async ({ clientId, advertiserId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.file.getAdvertiserFiles(
        clientId,
        advertiserId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (apiError.isApi404Error) {
        return [];
      }

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const postUploadClientAdvertiserFiles = createAsyncThunk<
  void,
  {
    clientId: number;
    advertiserId: number;
    files: File[];
  },
  AsyncThunkConfig
>(
  'advertiser/postUploadClientAdvertiserFiles',
  async ({ clientId, advertiserId, files }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.file.postUploadClientAdvertiserFiles(
        advertiserId,
        files
      );

      await dispatch(getAllFilesByAdvertiserId({ clientId, advertiserId }));

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
