import React from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import { drawerListItemStyles } from 'layout/components/drawer/drawerListItemStyles';

export type DrawerListItemButtonProps = {
  component?: React.ElementType;
} & ListItemButtonProps &
  RouterLinkProps &
  React.RefAttributes<HTMLAnchorElement>;

export type DrawerListItemProps = {
  text?: string;
  icon?: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  notificationNumber?: number;
  ListItemButtonProps?: DrawerListItemButtonProps;
} & ListItemProps;

const useStyles = makeStyles()((theme) => drawerListItemStyles(theme));

function DrawerListItem({
  selected = false,
  disabled = false,
  icon,
  text,
  ListItemButtonProps,
  notificationNumber,
  ...props
}: DrawerListItemProps) {
  const { classes } = useStyles();
  const validNotificationNumber = notificationNumber ?? 0;
  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  const showTooltip = Boolean(text && matchesUpMd);

  const showListItemText = Boolean(text && !matchesUpMd);

  return (
    <div style={{ position: 'relative' }}>
      {validNotificationNumber > 0 && (
        <Stack
          sx={{
            borderRadius: 30,
            top: matchesUpMd ? -6 : 14,
            left: matchesUpMd ? 50 : 250,
            width: 20,
            height: 20,
            alignItems: 'center',
            background: 'red',
            position: 'absolute',
            zIndex: 999,
            color: 'white',
          }}
        >
          <div style={{ marginTop: 0, marginLeft: -1, fontSize: 13 }}>
            {notificationNumber}
          </div>
        </Stack>
      )}
      <ListItem disablePadding className={classes.listItem} {...props}>
        <Tooltip title={showTooltip ? text : null} placement="right">
          <ListItemButton
            selected={selected}
            disabled={disabled}
            className={classes.listItemButton}
            {...ListItemButtonProps}
          >
            <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
            {showListItemText && (
              <ListItemText className={classes.listItemText} primary={text} />
            )}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    </div>
  );
}

export default DrawerListItem;
