import { ReportObjective } from 'features/reports/campaigns/components/ReportToolbar/types';
import {
  DrillDownReportNames,
  ReportMetadata,
  ReportNames,
  ReportTabNamesAsParam,
  ReportType,
  ReportTypes,
  SupportedAdTypes,
  SupportedReportMetadata,
  SupportedReportName,
} from 'features/reports/campaigns/types';

export const sanitizeLabelName = (label: string) => {
  return label
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '-');
};

export const CURRENT_REPORT_DOM_ID = '__current-report-dom-id__';

// Clicks a report button with a label and a prefix. For example:
// prefix can be "kampus-export-csv", it will be appended with the label.
// The label can be "Line Items", it will be sanitized to "line-items"
export const clickReportButton = (
  prefix: string,
  label: string,
  suffix: string
) => {
  const id = `${prefix}-${sanitizeLabelName(label)}-${suffix}`;
  document
    // Line items => kampus-export-csv-line-items, uses label
    .getElementById(id)
    ?.click();
};

export const ORDERED_CAMPAIGN_REPORT_METADATA = {
  PROGRAMMATIC_OVERVIEW: {
    id: ReportNames.PROGRAMMATIC_OVERVIEW,
    reportName: ReportNames.PROGRAMMATIC_OVERVIEW,
    tabNameAsParam: ReportTabNamesAsParam.PROGRAMMATIC_OVERVIEW,
    label: 'Programmatic Overview',
    reportType: ReportTypes.OVERVIEW,
    availableObjectives: [
      ReportObjective.Conversions,
      ReportObjective.Revenue,
      ReportObjective.Clicks,
      ReportObjective.CompletionRate,
      ReportObjective.Viewability,
    ],
  },
  PROGRAMMATIC_OVERVIEW_WITH_CTV: {
    id: ReportNames.PROGRAMMATIC_OVERVIEW_WITH_CTV,
    reportName: ReportNames.PROGRAMMATIC_OVERVIEW_WITH_CTV,
    tabNameAsParam: ReportTabNamesAsParam.PROGRAMMATIC_OVERVIEW_WITH_CTV,
    label: 'Programmatic Overview',
    reportType: ReportTypes.OVERVIEW,
    availableObjectives: [
      ReportObjective.Conversions,
      ReportObjective.Revenue,
      ReportObjective.Clicks,
      ReportObjective.CompletionRate,
      ReportObjective.Viewability,
    ],
  },
  CTV: {
    id: DrillDownReportNames.CTV,
    reportName: DrillDownReportNames.CTV,
    tabNameAsParam: ReportTabNamesAsParam.CTV,
    label: 'CTV',
    adTypes: [SupportedAdTypes.CTV],
    reportType: ReportTypes.DRILL_DOWN,
    availableObjectives: [
      ReportObjective.Conversions,
      ReportObjective.Revenue,
      ReportObjective.Clicks,
      ReportObjective.CompletionRate,
      ReportObjective.Viewability,
    ],
  },
  DISPLAY: {
    id: DrillDownReportNames.DISPLAY,
    reportName: DrillDownReportNames.DISPLAY,
    tabNameAsParam: ReportTabNamesAsParam.DISPLAY,
    label: 'Display',
    adTypes: [SupportedAdTypes.BANNER, SupportedAdTypes.RICH_MEDIA],
    reportType: ReportTypes.DRILL_DOWN,
    availableObjectives: [
      ReportObjective.Conversions,
      ReportObjective.Revenue,
      ReportObjective.Clicks,
      ReportObjective.Viewability,
    ],
  },
  NATIVE: {
    id: DrillDownReportNames.NATIVE,
    reportName: DrillDownReportNames.NATIVE,
    tabNameAsParam: ReportTabNamesAsParam.NATIVE,
    label: 'Native',
    adTypes: [SupportedAdTypes.NATIVE],
    reportType: ReportTypes.DRILL_DOWN,
    availableObjectives: [
      ReportObjective.Conversions,
      ReportObjective.Revenue,
      ReportObjective.Clicks,
    ],
  },
  VIDEO: {
    id: DrillDownReportNames.VIDEO,
    reportName: DrillDownReportNames.VIDEO,
    tabNameAsParam: ReportTabNamesAsParam.VIDEO,
    label: 'Online Video',
    adTypes: [SupportedAdTypes.VIDEO],
    reportType: ReportTypes.DRILL_DOWN,
    availableObjectives: [
      ReportObjective.Conversions,
      ReportObjective.Revenue,
      ReportObjective.Clicks,
      ReportObjective.CompletionRate,
      ReportObjective.Viewability,
    ],
  },
  AUDIO: {
    id: DrillDownReportNames.AUDIO,
    reportName: DrillDownReportNames.AUDIO,
    tabNameAsParam: ReportTabNamesAsParam.AUDIO,
    label: 'Audio',
    adTypes: [SupportedAdTypes.AUDIO],
    reportType: ReportTypes.DRILL_DOWN,
    availableObjectives: [
      ReportObjective.Conversions,
      ReportObjective.Revenue,
      ReportObjective.CompletionRate,
    ],
  },
  RICH_MEDIA_ENGAGEMENTS: {
    id: ReportNames.RICH_MEDIA_ENGAGEMENTS,
    reportName: ReportNames.RICH_MEDIA_ENGAGEMENTS,
    tabNameAsParam: ReportTabNamesAsParam.RICH_MEDIA_ENGAGEMENTS,
    label: 'Rich Media Engagements',
    reportType: ReportTypes.STANDARD,
    availableObjectives: undefined,
  },
  DIGITAL_OUT_OF_HOME: {
    id: ReportNames.DIGITAL_OUT_OF_HOME,
    reportName: ReportNames.DIGITAL_OUT_OF_HOME,
    tabNameAsParam: ReportTabNamesAsParam.DIGITAL_OUT_OF_HOME,
    label: 'Digital Out of Home',
    reportType: ReportTypes.STANDARD,
    availableObjectives: undefined,
  },
  CROSS_CHANNEL: {
    id: ReportNames.CROSS_CHANNEL,
    reportName: ReportNames.CROSS_CHANNEL,
    tabNameAsParam: ReportTabNamesAsParam.CROSS_CHANNEL,
    label: 'Cross-Channel',
    reportType: ReportTypes.STANDARD,
    availableObjectives: undefined,
  },
} satisfies Record<SupportedReportName, SupportedReportMetadata>;

export const reportNameMetadataInDefaultSortOrder: SupportedReportMetadata[] =
  Object.values(ORDERED_CAMPAIGN_REPORT_METADATA);

export const overviewReportNames: string[] =
  reportNameMetadataInDefaultSortOrder
    .filter((report) => report.reportType === ReportTypes.OVERVIEW)
    .map((report) => report.reportName);

export const defaultCampaignReportSortOrderByName: string[] =
  reportNameMetadataInDefaultSortOrder.map((value) => value.reportName);

const overviewGeneratedReports: readonly ReportType[] = [
  ReportTypes.OVERVIEW,
  ReportTypes.DRILL_DOWN,
] as const;

export const reportReliesOnOverview = (metadata: ReportMetadata | undefined) =>
  metadata && overviewGeneratedReports.includes(metadata.reportType);

export const reportIsStandard = (metadata: ReportMetadata | undefined) =>
  metadata && metadata.reportType === ReportTypes.STANDARD;

export const reportIsCrossChannel = (metadata: ReportMetadata | undefined) =>
  metadata && metadata.reportName === ReportNames.CROSS_CHANNEL;

// query names in this list don't respond to filters.
// e.g. A forecast that does not respond to date filters.
export const dateFilterExclusionList = [
  // Programmatic Overview / Forecast - Clicks
  'clicks_14days_forecast',
  'overview_ctv_clicks_14days_forecast',
  // Programmatic Overview / Forecast - Conversions
  'conversions_14days_forecast',
  'overview_ctv_conversions_14days_forecast',
];

export const platformQueryNames = ['campaign_cross_channel_platforms'];

export const platformAdTypeQueryNames = ['campaign_cross_channel_ad_types'];

export const pixelQueryNames = [
  'overview_pixels',
  'overview_ctv_pixels',
  'rich_media_engagements_pixels',
  'campaign_cross_channel_pixels',
];
