import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportWithClientSpecificationsOutputDto } from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';

export const getAllReportsForClient = createAsyncThunk<
  ReportWithClientSpecificationsOutputDto[],
  {
    clientId: number;
  }
>(
  'clientReports/getAllReportsForClient',
  async ({ clientId }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.clientReports.getAllReportSpecificationsForClient(clientId);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
