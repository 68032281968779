import { useTheme } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';

const paletteOptions = {
  primary: {
    main: '#00CCFF',
  },
  secondary: {
    main: '#001830',
  },
  kortxAccentBlue: {
    light: '#EAECFC',
    main: '#6672E8',
  },
  kortxAccentGreen: {
    light: '#E0F9ED',
    main: '#09D898',
  },
  kortxAccentMagenta: {
    light: '#F7E2ED',
    main: '#D63384',
  },
  kortxAccentYellow: {
    light: '#FFF8E0',
    main: '#FECD00',
  },
};

export const lightPalette = createPalette(paletteOptions);

export const darkPalette = createPalette({
  mode: 'dark',
  ...paletteOptions,
});

export const pieChartPalette: string[] = [
  '#00CCFF', // light blue
  '#6366F1', // purple
  '#FBBF24', // yellow
  '#4ADE80', // aqua
  '#334659', // dark blue
  '#D946EF', // magenta
  '#F97316', // orange
];

// Having a larger amount of gradient points makes the jump between the points less jarring. Additionally, if we have
// too few, the data points get blurry/translucent on zoom due to the first "transparent" point's alpha
export const GOOGLE_HEATMAP_GRADIENT = [
  'rgba(0, 0, 0, 0)',
  '#1C9F53',
  '#46A344',
  '#70A735',
  '#9AAB25',
  '#C4B016',
  '#EEB406',
  '#EA9C0F',
  '#E78419',
  '#E36C22',
  '#E0542C',
  '#DC3C35',
];

export function useKortxGradient() {
  const theme = useTheme();

  return ['#EFFAFF', theme.palette.primary.main, theme.palette.secondary.main];
}
