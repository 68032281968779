import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CampaignDto,
  CampaignFileDto,
  CustomReportDto,
  ProvenProcessStepInfoDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';

export const getSelectedCampaignById = createAsyncThunk<
  CampaignDto,
  {
    clientId: number;
    id: number;
  }
>(
  'campaignById/getSelectedCampaignById',
  async ({ clientId, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.campaign.getCampaignByClientIdAndId(
        clientId,
        id
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getCustomReportsForCampaign = createAsyncThunk<
  CustomReportDto[],
  {
    campaignId: number;
  }
>(
  'campaignById/getCustomReportsForCampaign',
  async ({ campaignId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.customReporting.getCustomReportsForCampaign(
        campaignId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);
      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }
      return rejectWithValue(apiError);
    }
  }
);

export const getProvenProcessInfo = createAsyncThunk<
  ProvenProcessStepInfoDto,
  {
    campaignId: number;
  }
>(
  'provenProcessInfo/getProvenProcessByCampaignId',
  async ({ campaignId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.provenProcess.getProvenProcessByCampaignId(
        campaignId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllFilesByCampaignId = createAsyncThunk<
  CampaignFileDto[],
  {
    campaignId: number;
  }
>(
  'campaignById/getAllFilesByCampaignId',
  async ({ campaignId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.file.getAllFilesByCampaignId(campaignId);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const postUploadClientCampaignFiles = createAsyncThunk<
  void,
  {
    campaignId: number;
    files: File[];
  }
>(
  'campaignById/postUploadClientCampaignFiles',
  async ({ campaignId, files }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.file.postUploadClientCampaignFiles(
        campaignId,
        files
      );

      await dispatch(getAllFilesByCampaignId({ campaignId }));

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
