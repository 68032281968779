import {
  CampaignAsTableRowProjectionDto,
  CampaignDto,
  CampaignDtoStatusEnum,
  CampaignMetricsDto,
  RequestForProposalFormOutputDto,
} from '@kortxio/hub-api';
import { getDaysRemainingForCampaign } from 'features/campaigns/helper';
import { RequestState } from 'features/shared/request';
import { ObjectValues } from 'libs/types/types';

export const CampaignsTabs = {
  Active: 'active',
  All: 'all',
  Proposals: 'proposals',
} as const;

export const allCampaignsTabs = Object.values(CampaignsTabs);

export type CampaignsTab = ObjectValues<typeof CampaignsTabs>;

export type CampaignAsOption = {
  id: number;
  label: string;
  campaignStartDate: string;
  campaignEndDate: string;
};

export type CampaignAsRow = {
  id?: number;
  name?: string;
  advertiserId?: number;
  advertiserName?: string;
  advertiserUrl?: string;
  status?: CampaignDtoStatusEnum;
  blocker?: string;
  startDate?: string;
  endDate?: string;
  budget?: number;
  deliveredBudget?: number;
  daysRemaining: number;
};

export const dtoToCampaignAsRow = (dto: CampaignDto): CampaignAsRow => {
  return {
    id: dto.id,
    name: dto.campaignName,
    advertiserId: dto.advertiser?.id,
    advertiserName: dto.advertiser?.name,
    advertiserUrl: dto.advertiser?.website,
    status: dto.status,
    blocker: dto.blocker?.reason,
    startDate: dto.campaignStartDate || dto.estStartDate,
    endDate: dto.campaignEndDate || dto.estEndDate,
    daysRemaining: getDaysRemainingForCampaign(dto),
    budget: dto.totalBudget,
    deliveredBudget: dto.totalDeliveredBudget,
  };
};

export const tableRowProjectionDtoToCampaignAsRow = (
  dto: CampaignAsTableRowProjectionDto
): CampaignAsRow => {
  return {
    id: dto.id,
    name: dto.name,
    advertiserId: dto.advertiserId,
    advertiserName: dto.advertiserName,
    advertiserUrl: dto.advertiserWebsite,
    status: dto.status,
    blocker: dto.blockerReason,
    startDate: dto.startDate || dto.estimatedStartDate,
    endDate: dto.endDate || dto.estimatedEndDate,
    daysRemaining: getDaysRemainingForCampaign(dto),
    budget: dto.budget,
    deliveredBudget: dto.deliveredBudget,
  };
};

export type RequestForProposalFormAsRow = {
  id?: number;
  campaignName?: string;
  campaignId?: number;
  advertiser?: string;
  status?: string;
  dueDate?: string;
  submittedAt?: string;
};

export enum GetAllCampaignsQueryType {
  ALL = 'ALL',
  ALL_BY_STATUS_IN = 'ALL_BY_STATUS_IN',
  ALL_BY_ADVERTISER_ID = 'ALL_BY_ADVERTISER_ID',
  ALL_USING_REPORTING_SUITE_SEARCH = 'ALL_USING_REPORTING_SUITE_SEARCH',
  ALL_BY_ADVERTISER_ID_USING_REPORTING_SUITE_SEARCH = 'ALL_BY_ADVERTISER_ID_USING_REPORTING_SUITE_SEARCH',
}

export type CampaignsUiState = {
  searchForCampaignsOrRequestForProposalForms: string | undefined;
  searchForCampaignsByAdvertiserId: string | undefined;
};

export type CampaignsState = {
  campaigns: {
    data: CampaignDto[] | undefined;
    request: RequestState;
  };
  campaignsByStatusIn: {
    data: CampaignDto[] | undefined;
    request: RequestState;
  };
  campaignsByAdvertiserId: {
    data: CampaignDto[] | undefined;
    request: RequestState;
  };
  campaignsByAdvertiserIdAndPlatformIn: {
    data: CampaignDto[] | undefined;
    request: RequestState;
  };
  campaignsUsingTableRowProjection: {
    data: CampaignAsTableRowProjectionDto[] | undefined;
    request: RequestState;
  };
  campaignsByStatusInUsingTableRowProjection: {
    data: CampaignAsTableRowProjectionDto[] | undefined;
    request: RequestState;
  };
  campaignsByAdvertiserIdUsingTableRowProjection: {
    data: CampaignAsTableRowProjectionDto[] | undefined;
    request: RequestState;
  };
  metrics: {
    data: CampaignMetricsDto | undefined;
    request: RequestState;
  };
  requestForProposalForms: {
    count: number | undefined;
    data: RequestForProposalFormOutputDto[] | undefined;
    request: RequestState;
  };
  ui: CampaignsUiState;
};
