import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { privilegesSelector } from 'features/user/selectors';

type RequirePermissionsProps = {
  requiredPrivileges: string[];
  redirectTo?: string;
  fallback?: React.ReactNode;
} & React.PropsWithChildren;

// A privilege is not a permission. See any WithHarPermissionTo* components for permissions.
export default function RequirePrivilege({
  requiredPrivileges,
  redirectTo,
  fallback,
  children,
}: RequirePermissionsProps) {
  const privileges = useSelector(privilegesSelector);

  const hasAllRequiredPrivileges: boolean = useMemo(() => {
    return requiredPrivileges.every((value) => privileges?.includes(value));
  }, [privileges, requiredPrivileges]);

  if (hasAllRequiredPrivileges) {
    return <>{children}</>;
  }

  if (privileges !== undefined && redirectTo !== undefined) {
    return <Navigate to={redirectTo} replace />;
  }

  if (privileges !== undefined && fallback != undefined) {
    return <>{fallback}</>;
  }

  return <></>;
}
