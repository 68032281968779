import { useParams } from 'react-router-dom';
import { CAMPAIGN_TAB, CampaignTab } from 'features/campaign/types';
import { selectedClientSelector } from 'features/client/selectors';
import { useAppSelector } from 'store/hooks';
import { roleSelector } from '../features/user/selectors';

type RouteOverrides = {
  advertiserId?: string | number;
  campaignId?: string | number;
  clientId?: string | number;
  campaignActiveTab?: CampaignTab;
  audienceId?: string | number;
  campaignReportingActiveTab?: string;
};

export const useKampusRoutes = (overrides?: RouteOverrides) => {
  const {
    advertiserId: advertiserIdAsParam,
    campaignId: campaignIdAsParam,
    clientId: clientIdAsParam,
    campaignActiveTab: campaignActiveTabAsParam,
    audienceId,
    activeTab: campaignReportingActiveTabAsParam,
  } = useParams();

  const client = useAppSelector(selectedClientSelector);
  const { id: selectedClientId } = client ?? {};

  const advertiserId = overrides?.advertiserId ?? advertiserIdAsParam;
  const campaignId = overrides?.campaignId ?? campaignIdAsParam;
  const clientId = overrides?.clientId ?? selectedClientId ?? clientIdAsParam;
  const campaignActiveTab =
    overrides?.campaignActiveTab ??
    campaignActiveTabAsParam ??
    CAMPAIGN_TAB.Overview;
  const campaignReportingActiveTab =
    overrides?.campaignReportingActiveTab ?? campaignReportingActiveTabAsParam;

  const role = useAppSelector(roleSelector);

  return {
    baseClientRoute: createBaseClientRoute(clientId),
    routeHome: createRouteToHome(clientId, role),
    routeAdvertisers: createRouteToAdvertisers(clientId),
    routeAdvertiserById: createRouteToAdvertiserById(clientId, advertiserId),
    routeAudiences: createRouteToAudiences(clientId),
    routeAudienceTaxonomy: createRouteToAudienceTaxonomy(
      clientId,
      advertiserId
    ),
    routeAudienceSegmentAnalysis: createRouteToAudienceSegmentAnalysis(
      clientId,
      advertiserId
    ),
    routeAudienceWebInsights: createRouteToAudienceWebInsights(
      clientId,
      advertiserId,
      audienceId
    ),
    routeAudienceAudienceComposition: createRouteToAudienceComposition(
      clientId,
      advertiserId,
      audienceId
    ),
    routeCampaigns: createRouteToCampaigns(clientId),
    routeCampaignById: createRouteToCampaignById(
      clientId,
      campaignId,
      campaignActiveTab
    ),
    routeCampaignByIdWithUpdates: createRouteToCampaignById(
      clientId,
      campaignId,
      campaignActiveTab,
      true
    ),
    routeReportingSuite: createRouteToReportingSuite(clientId),
    createRouteToReportingSuiteForAllAdvertisers:
      createRouteToReportingSuiteForAllAdvertisers(clientId),
    routeReportingSuiteByAdvertiserId:
      createRouteToReportingSuiteByAdvertiserId(clientId, advertiserId),
    routeCampaignReportingForAllAdvertisers:
      createRouteToCampaignReportingForAllAdvertisers(clientId),
    routeCampaignReporting: createRouteToCampaignReporting(
      clientId,
      advertiserId
    ),
    routeCampaignReportingByCampaignIdForAllAdvertisers:
      createRouteToCampaignReportingByCampaignIdForAllAdvertisers(
        clientId,
        campaignId
      ),
    routeCampaignReportingByCampaignId:
      createRouteToCampaignReportingByCampaignId(
        clientId,
        advertiserId,
        campaignId
      ),
    routeCampaignReportingTabByCampaignIdForAllAdvertisers:
      createRouteToCampaignReportingTabByCampaignIdForAllAdvertisers(
        clientId,
        campaignId,
        campaignReportingActiveTab
      ),
    routeCampaignReportingTabByCampaignId:
      createRouteToCampaignReportingTabByCampaignId(
        clientId,
        advertiserId,
        campaignId,
        campaignReportingActiveTab
      ),
    routeDataConnections: createRouteToReportingDataConnections(
      clientId,
      advertiserId
    ),
    routeAIAssistant: createRouteToAIAssistant(
      clientId,
      advertiserId,
      campaignId
    ),
    routeDashboardsForAllAdvertisers:
      createRouteToReportingDashboardsForAllAdvertisers(clientId),
    routeDashboards: createRouteToReportingDashboards(clientId, advertiserId),
    routeReportingSuiteBuilder: createRouteToReportingSuiteBuilder(
      clientId,
      advertiserId
    ),
    routeReportingSuiteBuilderSchedules:
      createRouteToReportingSuiteBuilderSchedules(clientId, advertiserId),
  };
};

export const createBaseClientRoute = (clientId?: string | number) => {
  return `/clients/${clientId}`;
};

// Not really home anymore since home was removed
export const createRouteToHome = (
  clientId?: string | number,
  role?: string
) => {
  return role === 'REPORTING_ONLY'
    ? `/clients/${clientId}/reporting-suite/all/dashboards`
    : `/clients/${clientId}/campaigns`;
};

export const createRouteToAdvertisers = (clientId?: string | number) => {
  return `/clients/${clientId}/advertisers`;
};

export const createRouteToAdvertiserById = (
  clientId?: string | number,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/advertisers/${advertiserId}`;
};
``;

export const createRouteToAudiences = (clientId?: number | string) => {
  return `/clients/${clientId}/audiences`;
};

export const createRouteToAudienceSegmentAnalysis = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/segment-analysis`;
};

export const createRouteToAudienceWebInsights = (
  clientId?: number | string,
  advertiserId?: number | string,
  audienceId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/segment-analysis/${audienceId}/web-insights`;
};

export const createRouteToAudienceComposition = (
  clientId?: number | string,
  advertiserId?: number | string,
  audienceId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/segment-analysis/${audienceId}/audience-composition`;
};

export const createRouteToAudienceTaxonomy = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/taxonomy`;
};

export const createRouteToCampaigns = (clientId?: string | number) => {
  return `/clients/${clientId}/campaigns`;
};

export const createRouteToCampaignById = (
  clientId?: string | number,
  campaignId?: number | string,
  tab?: string,
  openUpdates?: boolean
) => {
  return openUpdates
    ? `/clients/${clientId}/campaigns/${campaignId}/${tab}?openUpdates=${openUpdates}`
    : `/clients/${clientId}/campaigns/${campaignId}/${tab}`;
};

export const createRouteToReportingSuite = (clientId?: number | string) => {
  return `/clients/${clientId}/reporting-suite`;
};

export const createRouteToReportingSuiteForAllAdvertisers = (
  clientId?: number | string
) => {
  return `/clients/${clientId}/reporting-suite/all`;
};

export const createRouteToReportingSuiteByAdvertiserId = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/reporting-suite/${advertiserId}`;
};

export const createRouteToCampaignReportingForAllAdvertisers = (
  clientId?: number | string
) => {
  const reportingSuiteRoute =
    createRouteToReportingSuiteForAllAdvertisers(clientId);

  return `${reportingSuiteRoute}/campaign-reporting`;
};

export const createRouteToCampaignReporting = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteByAdvertiserId(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/campaign-reporting`;
};

export const createRouteToCampaignReportingByCampaignIdForAllAdvertisers = (
  clientId?: number | string,
  campaignId?: number | string
) => {
  const reportingSuiteRoute =
    createRouteToReportingSuiteForAllAdvertisers(clientId);

  return `${reportingSuiteRoute}/campaign-reporting/${campaignId}`;
};

export const createRouteToCampaignReportingByCampaignId = (
  clientId?: number | string,
  advertiserId?: number | string,
  campaignId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteByAdvertiserId(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/campaign-reporting/${campaignId}`;
};

export const createRouteToCampaignReportingTabByCampaignIdForAllAdvertisers = (
  clientId?: number | string,
  campaignId?: number | string,
  activeTab?: string
) => {
  const campaignReportingRoute =
    createRouteToCampaignReportingByCampaignIdForAllAdvertisers(
      clientId,
      campaignId
    );

  return `${campaignReportingRoute}/${activeTab}`;
};

export const createRouteToCampaignReportingTabByCampaignId = (
  clientId?: number | string,
  advertiserId?: number | string,
  campaignId?: number | string,
  activeTab?: string
) => {
  const campaignReportingRoute = createRouteToCampaignReportingByCampaignId(
    clientId,
    advertiserId,
    campaignId
  );

  return `${campaignReportingRoute}/${activeTab}`;
};

export const createRouteToReportingDataConnections = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteByAdvertiserId(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/data-connections`;
};

export const createRouteToAIAssistant = (
  clientId?: string | number,
  advertiserId?: string | number,
  campaignId?: string | number
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteByAdvertiserId(
    clientId,
    advertiserId
  );

  return campaignId
    ? `${reportingSuiteRoute}/assistant/${campaignId}`
    : `${reportingSuiteRoute}/assistant`;
};

export const createRouteToReportingDashboardsForAllAdvertisers = (
  clientId?: number | string
) => {
  const reportingSuiteRoute =
    createRouteToReportingSuiteForAllAdvertisers(clientId);

  return `${reportingSuiteRoute}/dashboards`;
};

export const createRouteToReportingDashboards = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteByAdvertiserId(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/dashboards`;
};

export const createRouteToReportingSuiteBuilder = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteByAdvertiserId(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/builder`;
};

export const createRouteToReportingSuiteBuilderSchedules = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteBuilderRoute = createRouteToReportingSuiteBuilder(
    clientId,
    advertiserId
  );

  return `${reportingSuiteBuilderRoute}/schedules`;
};
