import { createSelector } from '@reduxjs/toolkit';
import {
  ClientDto,
  ClientTeamDto,
  CustomReportDto,
  CustomReportDtoDestinationEnum,
} from '@kortxio/hub-api';
import {
  ClientAsOption,
  ClientState,
  ClientUiState,
  ContactAccountManagerDialogState,
} from 'features/client/types';
import { RequestState } from 'features/shared/request';
import { DashboardDto } from 'libs/gen/analyticsBaseApi';
import { RootState } from 'store/types';

export const clientRootSelector = (state: RootState): ClientState =>
  state.client;

export const clientsDataSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): ClientDto[] | undefined => {
    if (!clientRoot) {
      return undefined;
    }

    const { clients } = clientRoot;

    if (!clients) {
      return undefined;
    }

    const { data } = clients;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const clientsRequestSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): RequestState | undefined => {
    if (!clientRoot) {
      return undefined;
    }

    const { clients } = clientRoot;

    if (!clients) {
      return undefined;
    }

    const { request } = clients;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const clientsSelector = clientsDataSelector;

export const clientsAsOptionsSelector = createSelector(
  clientsSelector,
  (clients): ClientAsOption[] => {
    if (!clients) {
      return [];
    }

    return clients
      .map((value) => ({ id: value.id, label: value.name } as ClientAsOption))
      .sort((a, b) => a.label.trim().localeCompare(b.label.trim()));
  }
);

export const selectedClientDataSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): ClientDto | undefined => {
    if (!clientRoot) {
      return undefined;
    }

    const { selectedClient } = clientRoot;

    if (!selectedClient) {
      return undefined;
    }

    const { data } = selectedClient;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const selectedClientRequestSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): RequestState | undefined => {
    if (!clientRoot) {
      return undefined;
    }

    const { selectedClient } = clientRoot;

    if (!selectedClient) {
      return undefined;
    }

    const { request } = selectedClient;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const selectedClientSelector = selectedClientDataSelector;

export const selectedClientAsOptionSelector = createSelector(
  selectedClientDataSelector,
  (selectedClient): ClientAsOption | undefined => {
    if (!selectedClient) {
      return undefined;
    }

    return {
      id: selectedClient.id,
      label: selectedClient.name,
    } as ClientAsOption;
  }
);

export const customReportsForClientRequestSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState) => clientRoot.customReports?.request
);

export const customReportsForClientDataSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): CustomReportDto[] | undefined =>
    clientRoot?.customReports?.data
);

export const customReportsForClientAsDashboardsSelector = createSelector(
  customReportsForClientDataSelector,
  (
    customReports: CustomReportDto[] | undefined
  ): (DashboardDto & { description: string })[] | undefined => {
    if (customReports && Array.isArray(customReports)) {
      return customReports
        .filter(
          (customReport) =>
            customReport.destination ===
            CustomReportDtoDestinationEnum.ClientDashboard
        )
        .map((customReport) => toDashboardDto(customReport));
    }
  }
);

function toDashboardDto(
  report: CustomReportDto
): DashboardDto & { description: string } {
  const params = [
    report.url ? 'url=' + report.url : '',
    report.name ? 'name=' + report.name : '',
  ].join('&');
  return {
    sourceTypes: ['CUSTOM_REPORTS'],
    id: 'custom-report?' + params,
    name: report.name ?? '',
    url: 'custom-report?' + report.url,
    description: report.description ?? '',
  };
}

export const teamDataSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): ClientTeamDto | undefined => {
    if (!clientRoot) {
      return undefined;
    }

    const { team } = clientRoot;

    if (!team) {
      return undefined;
    }

    const { data } = team;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const teamRequestSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): RequestState | undefined => {
    if (!clientRoot) {
      return undefined;
    }

    const { team } = clientRoot;

    if (!team) {
      return undefined;
    }

    const { request } = team;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const teamSelector = teamDataSelector;

export const accountManagerPhotoBlobUrlDataSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): string | undefined => {
    const { accountManagerPhotoBlobUrl } = clientRoot;

    if (!accountManagerPhotoBlobUrl) {
      return undefined;
    }

    const { data } = accountManagerPhotoBlobUrl;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const accountManagerPhotoBlobUrlRequestSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): RequestState | undefined => {
    const { accountManagerPhotoBlobUrl } = clientRoot;

    if (!accountManagerPhotoBlobUrl) {
      return undefined;
    }

    const { request } = accountManagerPhotoBlobUrl;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const accountManagerPhotoBlobUrlSelector =
  accountManagerPhotoBlobUrlDataSelector;

export const clientUiSelector = createSelector(
  clientRootSelector,
  (clientRoot: ClientState): ClientUiState | undefined => {
    if (!clientRoot) {
      return undefined;
    }

    const { ui } = clientRoot;

    if (!ui) {
      return undefined;
    }

    return ui;
  }
);

export const contactAccountManagerDialogSelector = createSelector(
  clientUiSelector,
  (
    ui: ClientUiState | undefined
  ): ContactAccountManagerDialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { contactAccountManagerDialog } = ui;

    if (!contactAccountManagerDialog) {
      return undefined;
    }

    return contactAccountManagerDialog;
  }
);
