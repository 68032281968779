import {
  InvoiceDtoWithMetadata,
  InvoiceDtoWithMetadataStatusEnum,
  InvoiceMetricsDto,
} from '@kortxio/hub-api';
import { RequestState } from 'features/shared/request';

export const defaultSortOrder: string[] = [
  InvoiceDtoWithMetadataStatusEnum.Overdue,
  InvoiceDtoWithMetadataStatusEnum.Open,
  InvoiceDtoWithMetadataStatusEnum.Paid,
];

export type InvoiceAsRow = {
  id?: string;
  docNumber?: string;
  totalAmount?: number;
  balance?: number;
  transactionDate?: string;
  dueDate?: string;
  status?: InvoiceDtoWithMetadataStatusEnum;
  advertiserId?: number;
  advertiserName?: string;
  campaignId?: number;
  campaignName?: string;
};

export const dtoToInvoiceAsRow = (
  dto: InvoiceDtoWithMetadata
): InvoiceAsRow | undefined => {
  if (!dto) {
    return undefined;
  }

  return {
    id: dto.id,
    docNumber: dto.docNumber,
    totalAmount: dto.totalAmount,
    balance: dto.balance,
    transactionDate: dto.transactionDate,
    dueDate: dto.dueDate,
    status: dto.status,
    advertiserId: dto.advertiserId ?? undefined,
    advertiserName: dto.advertiserName ? dto.advertiserName : dto.customerName,
    campaignId: dto.campaignId ?? undefined,
    campaignName: dto.campaignName ? dto.campaignName : 'N/A',
  };
};

export type InvoiceContactDialogState = {
  isOpen: boolean;
  contentType: 'form' | 'confirmation';
  selectedInvoice: InvoiceDtoWithMetadata | undefined;
};

export type InvoiceUiState = {
  search: string | undefined;
  contactDialog: InvoiceContactDialogState;
};

export type InvoiceState = {
  invoices: {
    data: InvoiceDtoWithMetadata[] | undefined;
    request: RequestState;
  };
  metrics: {
    data: InvoiceMetricsDto | undefined;
    request: RequestState;
  };
  ui: InvoiceUiState;
};
