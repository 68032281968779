import { createSelector } from '@reduxjs/toolkit';
import { InvoiceDtoWithMetadata, InvoiceMetricsDto } from '@kortxio/hub-api';
import { RequestState } from 'features/shared/request';
import { compareDateDesc } from 'libs/date';
import { RootState } from 'store/types';
import {
  defaultSortOrder,
  dtoToInvoiceAsRow,
  InvoiceAsRow,
  InvoiceContactDialogState,
  InvoiceState,
  InvoiceUiState,
} from './types';

export const invoiceRootSelector = (state: RootState): InvoiceState =>
  state.invoice;

export const invoicesDataSelector = createSelector(
  invoiceRootSelector,
  (invoiceRoot: InvoiceState): InvoiceDtoWithMetadata[] | undefined => {
    if (!invoiceRoot) {
      return undefined;
    }

    const { invoices } = invoiceRoot;

    if (!invoices) {
      return undefined;
    }

    const { data } = invoices;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const invoicesRequestSelector = createSelector(
  invoiceRootSelector,
  (invoiceRoot: InvoiceState): RequestState | undefined => {
    if (!invoiceRoot) {
      return undefined;
    }

    const { invoices } = invoiceRoot;

    if (!invoices) {
      return undefined;
    }

    const { request } = invoices;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const invoicesSelector = invoicesDataSelector;

export const invoiceByIdSelector = (id: string) =>
  createSelector(
    invoicesSelector,
    (invoices): InvoiceDtoWithMetadata | undefined => {
      if (!invoices) {
        return undefined;
      }

      return invoices.find((invoice) => invoice?.id === id);
    }
  );

export const invoicesAsRowsSelector = createSelector(
  invoicesSelector,
  (invoices): InvoiceAsRow[] => {
    if (!invoices) {
      return [];
    }

    return [...invoices]
      .filter((invoice) => invoice != undefined)
      .map((invoice) => dtoToInvoiceAsRow(invoice) as InvoiceAsRow)
      .sort((a, b) => {
        if (!a.status) {
          return 1;
        }

        if (!b.status) {
          return -1;
        }

        return (
          defaultSortOrder.indexOf(a.status) -
            defaultSortOrder.indexOf(b.status) ||
          compareDateDesc(a.transactionDate, b.transactionDate)
        );
      });
  }
);

export const invoicesAsRowsWithSearchSelector = (search: string) =>
  createSelector(invoicesAsRowsSelector, (invoicesAsRows): InvoiceAsRow[] => {
    return invoicesAsRows.filter((invoice) => {
      const campaignName = invoice.campaignName ?? '';
      const advertiserName = invoice.advertiserName ?? '';

      return (
        campaignName.toLowerCase().includes(search.toLowerCase()) ||
        advertiserName.toLowerCase().includes(search.toLowerCase())
      );
    });
  });

export const invoiceMetricsDataSelector = createSelector(
  invoiceRootSelector,
  (invoiceRoot: InvoiceState): InvoiceMetricsDto | undefined => {
    if (!invoiceRoot) {
      return undefined;
    }

    const { metrics } = invoiceRoot;

    if (!metrics) {
      return undefined;
    }

    const { data } = metrics;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const invoiceMetricsRequestSelector = createSelector(
  invoiceRootSelector,
  (invoiceRoot: InvoiceState): RequestState | undefined => {
    if (!invoiceRoot) {
      return undefined;
    }

    const { metrics } = invoiceRoot;

    if (!metrics) {
      return undefined;
    }

    const { request } = metrics;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const invoiceMetricsSelector = invoiceMetricsDataSelector;

export const invoiceUiSelector = createSelector(
  invoiceRootSelector,
  (invoiceRoot: InvoiceState): InvoiceUiState | undefined => {
    if (!invoiceRoot) {
      return undefined;
    }

    const { ui } = invoiceRoot;

    if (!ui) {
      return undefined;
    }

    return ui;
  }
);

export const contactDialogSelector = createSelector(
  invoiceUiSelector,
  (ui: InvoiceUiState | undefined): InvoiceContactDialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { contactDialog } = ui;

    if (!contactDialog) {
      return undefined;
    }

    return contactDialog;
  }
);
