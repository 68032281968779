import { createSlice } from '@reduxjs/toolkit';
import {
  ReportDataDto,
  ReportWithCampaignSpecificationsOutputDto,
} from '@kortxio/hub-api';
import {
  getAllReportsForCampaign,
  getReportDefaultsForCampaign,
  runAdfireQuery,
  runBudgetGroupQuery,
  runDigitalOutOfHomeCampaignQuery,
  runPixelQuery,
  runPlatformQuery,
  runPlatformAdTypeQuery,
  runQuery,
} from 'features/reports/campaigns/async';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestActionsByType,
  requestReducer,
} from 'features/shared/request';
import {
  CampaignReportQueryResult,
  CampaignReportsState,
  RunQueryParams,
} from './types';

export const initialState: CampaignReportsState = {
  reports: {
    data: undefined,
    request: initialRequestState,
  },
  selectedReport: undefined,
  reportDefaults: {
    data: undefined,
    request: initialRequestState,
  },
  queryResults: undefined,
  budgetGroupQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
  platformQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
  platformAdTypeQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
  pixelQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
  isPixelSearchParamDirty: false,
  digitalOutOfHomeCampaignQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
  adfireQueryResults: undefined,
  ui: {
    searchForCampaignsInReportingSuite: undefined,
  },
};

const handleUpdateQueryResultsState = ({
  queryResultsBeforeUpdate,
  meta,
  payload,
}: {
  queryResultsBeforeUpdate: CampaignReportQueryResult[] | undefined;
  meta: {
    arg: RunQueryParams;
    requestId: string;
    requestStatus: 'fulfilled' | 'pending' | 'rejected';
  };
  payload: ReportDataDto | undefined;
}) => {
  const { arg } = meta || {};
  const { queryName } = arg || {};

  const queryResults =
    queryResultsBeforeUpdate != undefined
      ? queryResultsBeforeUpdate.filter((value) => value.name !== queryName)
      : [];

  const queryResultRequestForQueryNameBeforeUpdate =
    queryResultsBeforeUpdate?.find(
      (value) => value?.name === queryName
    )?.request;

  const queryResultsForQueryName = {
    name: queryName,
    data: payload,
    request: requestReducer(
      queryResultRequestForQueryNameBeforeUpdate,
      requestActionsByType[meta.requestStatus]({ meta })
    ),
  };

  return [...queryResults, queryResultsForQueryName];
};

export const slice = createSlice({
  name: 'campaignReports',
  initialState,
  reducers: {
    setSearchForCampaignsInReportingSuite(state, { payload }) {
      state.ui.searchForCampaignsInReportingSuite = payload;
    },
    setSelectedReport(
      state,
      {
        payload: report,
      }: { payload: ReportWithCampaignSpecificationsOutputDto | undefined }
    ) {
      state.selectedReport = report;
      state.reportDefaults = {
        data: undefined,
        request: initialRequestState,
      };
      state.queryResults = undefined;
      state.platformQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.platformAdTypeQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.pixelQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.digitalOutOfHomeCampaignQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
    },
    resetReports: (state) => {
      state.reports = {
        data: undefined,
        request: initialRequestState,
      };
      state.selectedReport = undefined;
      state.reportDefaults = {
        data: undefined,
        request: initialRequestState,
      };
      state.queryResults = undefined;
      state.platformQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.platformAdTypeQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.pixelQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.digitalOutOfHomeCampaignQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
    },
    setIsPixelSearchParamDirty(state, { payload }) {
      state.isPixelSearchParamDirty = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllReportsForCampaign.fulfilled,
      (state, { meta, payload }) => {
        if (state.reports.request.id === meta.requestId) {
          state.reports.data = payload;
          state.reports.request = requestReducer(
            state.reports.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getAllReportsForCampaign.pending, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        pending({ meta })
      );
    });
    builder.addCase(getAllReportsForCampaign.rejected, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getReportDefaultsForCampaign.fulfilled,
      (state, { meta, payload }) => {
        if (state.reportDefaults.request.id === meta.requestId) {
          state.reportDefaults.data = payload;
          state.reportDefaults.request = requestReducer(
            state.reportDefaults.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getReportDefaultsForCampaign.pending, (state, { meta }) => {
      state.reportDefaults.data = undefined;
      state.reportDefaults.request = requestReducer(
        state.reportDefaults.request,
        pending({ meta })
      );
    });

    builder.addCase(
      getReportDefaultsForCampaign.rejected,
      (state, { meta }) => {
        state.reportDefaults.data = undefined;
        state.reportDefaults.request = requestReducer(
          state.reportDefaults.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(runQuery.fulfilled, (state, { meta, payload }) => {
      const { arg } = meta || {};
      const { id } = arg || {};

      if (id === state.selectedReport?.id) {
        state.queryResults = handleUpdateQueryResultsState({
          queryResultsBeforeUpdate: state.queryResults,
          meta,
          payload,
        });
      }
    });

    builder.addCase(runQuery.pending, (state, { meta }) => {
      state.queryResults = handleUpdateQueryResultsState({
        queryResultsBeforeUpdate: state.queryResults,
        meta,
        payload: undefined,
      });
    });

    builder.addCase(runQuery.rejected, (state, { meta }) => {
      state.queryResults = handleUpdateQueryResultsState({
        queryResultsBeforeUpdate: state.queryResults,
        meta,
        payload: undefined,
      });
    });

    builder.addCase(
      runBudgetGroupQuery.fulfilled,
      (state, { meta, payload }) => {
        if (state.budgetGroupQueryResults.request.id === meta.requestId) {
          state.budgetGroupQueryResults.data = payload;
          state.budgetGroupQueryResults.request = requestReducer(
            state.budgetGroupQueryResults.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(runBudgetGroupQuery.pending, (state, { meta }) => {
      state.budgetGroupQueryResults.data = undefined;
      state.budgetGroupQueryResults.request = requestReducer(
        state.budgetGroupQueryResults.request,
        pending({ meta })
      );
    });

    builder.addCase(runBudgetGroupQuery.rejected, (state, { meta }) => {
      state.budgetGroupQueryResults.data = undefined;
      state.budgetGroupQueryResults.request = requestReducer(
        state.budgetGroupQueryResults.request,
        rejected({ meta })
      );
    });

    builder.addCase(runPlatformQuery.fulfilled, (state, { meta, payload }) => {
      if (state.platformQueryResults.request.id === meta.requestId) {
        state.platformQueryResults.data = payload;
        state.platformQueryResults.request = requestReducer(
          state.platformQueryResults.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(runPlatformQuery.pending, (state, { meta }) => {
      state.platformQueryResults.data = undefined;
      state.platformQueryResults.request = requestReducer(
        state.platformQueryResults.request,
        pending({ meta })
      );
    });

    builder.addCase(runPlatformQuery.rejected, (state, { meta }) => {
      state.platformQueryResults.data = undefined;
      state.platformQueryResults.request = requestReducer(
        state.platformQueryResults.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      runPlatformAdTypeQuery.fulfilled,
      (state, { meta, payload }) => {
        if (state.platformAdTypeQueryResults.request.id === meta.requestId) {
          state.platformAdTypeQueryResults.data = payload;
          state.platformAdTypeQueryResults.request = requestReducer(
            state.platformAdTypeQueryResults.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(runPlatformAdTypeQuery.pending, (state, { meta }) => {
      state.platformAdTypeQueryResults.data = undefined;
      state.platformAdTypeQueryResults.request = requestReducer(
        state.platformAdTypeQueryResults.request,
        pending({ meta })
      );
    });

    builder.addCase(runPlatformAdTypeQuery.rejected, (state, { meta }) => {
      state.platformAdTypeQueryResults.data = undefined;
      state.platformAdTypeQueryResults.request = requestReducer(
        state.platformAdTypeQueryResults.request,
        rejected({ meta })
      );
    });

    builder.addCase(runPixelQuery.fulfilled, (state, { meta, payload }) => {
      if (state.pixelQueryResults.request.id === meta.requestId) {
        state.pixelQueryResults.data = payload;
        state.pixelQueryResults.request = requestReducer(
          state.pixelQueryResults.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(runPixelQuery.pending, (state, { meta }) => {
      state.pixelQueryResults.data = undefined;
      state.pixelQueryResults.request = requestReducer(
        state.pixelQueryResults.request,
        pending({ meta })
      );
    });

    builder.addCase(runPixelQuery.rejected, (state, { meta }) => {
      state.pixelQueryResults.data = undefined;
      state.pixelQueryResults.request = requestReducer(
        state.pixelQueryResults.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      runDigitalOutOfHomeCampaignQuery.fulfilled,
      (state, { meta, payload }) => {
        if (
          state.digitalOutOfHomeCampaignQueryResults.request.id ===
          meta.requestId
        ) {
          state.digitalOutOfHomeCampaignQueryResults.data = payload;
          state.digitalOutOfHomeCampaignQueryResults.request = requestReducer(
            state.digitalOutOfHomeCampaignQueryResults.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      runDigitalOutOfHomeCampaignQuery.pending,
      (state, { meta }) => {
        state.digitalOutOfHomeCampaignQueryResults.data = undefined;
        state.digitalOutOfHomeCampaignQueryResults.request = requestReducer(
          state.digitalOutOfHomeCampaignQueryResults.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      runDigitalOutOfHomeCampaignQuery.rejected,
      (state, { meta }) => {
        state.digitalOutOfHomeCampaignQueryResults.data = undefined;
        state.digitalOutOfHomeCampaignQueryResults.request = requestReducer(
          state.digitalOutOfHomeCampaignQueryResults.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(runAdfireQuery.fulfilled, (state, { meta, payload }) => {
      state.adfireQueryResults = handleUpdateQueryResultsState({
        queryResultsBeforeUpdate: state.adfireQueryResults,
        meta,
        payload,
      });
    });

    builder.addCase(runAdfireQuery.pending, (state, { meta }) => {
      state.adfireQueryResults = handleUpdateQueryResultsState({
        queryResultsBeforeUpdate: state.adfireQueryResults,
        meta,
        payload: undefined,
      });
    });

    builder.addCase(runAdfireQuery.rejected, (state, { meta }) => {
      state.adfireQueryResults = handleUpdateQueryResultsState({
        queryResultsBeforeUpdate: state.adfireQueryResults,
        meta,
        payload: undefined,
      });
    });
  },
});

const { actions, reducer } = slice;

export const {
  setSearchForCampaignsInReportingSuite,
  setSelectedReport,
  resetReports,
  setIsPixelSearchParamDirty,
} = actions;

export default reducer;
