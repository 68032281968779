import { Theme, useMediaQuery } from '@mui/material';
import RequirePrivilege from 'features/user/components/RequirePrivilege';
import WithCampaignMetrics from '../../../features/campaigns/components/WithCampaignMetrics';
import WithInvoiceMetrics from '../../../features/invoice/components/WithInvoiceMetrics';
import MiniVariantDrawer from './MiniVariantDrawer';
import TemporaryDrawer from './TemporaryDrawer';

function KampusDrawer() {
  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  return (
    <>
      <WithCampaignMetrics />
      <RequirePrivilege requiredPrivileges={['INVOICES_READ']}>
        <WithInvoiceMetrics />
      </RequirePrivilege>
      {matchesUpMd && <MiniVariantDrawer />}
      {!matchesUpMd && <TemporaryDrawer />}
    </>
  );
}

export default KampusDrawer;
