import { ErrorHandlerProvider } from 'libs/error';

interface ApiConfig {
  readonly apiUrl: string;
  readonly analyticsApiUrl: string;
}

interface AuthConfig {
  readonly region: string;
  readonly userPoolId: string;
  readonly userPoolClientId: string;
}

interface AxonConfig {
  readonly axonUrl: string;
}

interface OAuthConfig {
  readonly redirectUrl: string;
}

interface MuiXConfig {
  readonly licenseKey: string;
}

interface SentryConfig {
  readonly isEnabled: boolean;
  readonly orgId: string;
  readonly dsn: string;
}

interface FullstoryConfig {
  readonly isEnabled: boolean;
  readonly orgId: string;
}

interface AppcuesConfig {
  readonly isEnabled: boolean;
}

interface MagicBellConfig {
  readonly isEnabled: boolean;
  readonly apiKey: string;
}

interface DapConfig {
  learnMoreUrl: string;
  dynamicEmbedNameFilter: string;
  dashboardFilterPresetVersion: string;
}

interface TexConfig {
  chatUrl: string;
}

interface GoogleConfig {
  mapsApiKey: string;
}

interface Config {
  readonly api: ApiConfig;
  readonly appcues: AppcuesConfig;
  readonly auth: AuthConfig;
  readonly axon: AxonConfig;
  readonly errorHandler: ErrorHandlerProvider;
  readonly google: GoogleConfig;
  readonly mui: MuiXConfig;
  readonly oauth: OAuthConfig;
  readonly s3BucketNameForPublicDocuments: string;
  readonly sentry: SentryConfig;
  readonly fullstory: FullstoryConfig;
  readonly magicBell: MagicBellConfig;
  readonly featureFlags: Record<string, boolean>;
  readonly dap: DapConfig;
  readonly tex: TexConfig;
}

export const initConfig = (): Config => {
  const cognito = {
    region: import.meta.env.VITE_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID,
    cookieStorageDomain: import.meta.env.VITE_COGNITO_COOKIE_STORAGE_DOMAIN,
  };

  return {
    api: {
      apiUrl: import.meta.env.VITE_API_URL,
      analyticsApiUrl: import.meta.env.VITE_ANALYTICS_API_URL,
    },
    appcues: {
      isEnabled: import.meta.env.VITE_ENABLE_APPCUES === 'true',
    },
    axon: {
      axonUrl: import.meta.env.VITE_AXON_URL,
    },
    oauth: {
      redirectUrl: import.meta.env.VITE_OAUTH_REDIRECT_URL,
    },
    auth: {
      region: cognito.region,
      userPoolId: cognito.userPoolId,
      userPoolClientId: cognito.userPoolClientId,
    },
    mui: {
      licenseKey: import.meta.env.VITE_MUI_X_LICENSE_KEY,
    },
    google: {
      mapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    },
    sentry: {
      isEnabled:
        import.meta.env.VITE_ERROR_HANDLER === ErrorHandlerProvider.sentry,
      orgId: 'kortx',
      dsn: import.meta.env.VITE_SENTRY_DSN,
    },
    errorHandler:
      ErrorHandlerProvider[
        import.meta.env.VITE_ERROR_HANDLER as keyof typeof ErrorHandlerProvider
      ] ?? ErrorHandlerProvider.console,
    s3BucketNameForPublicDocuments: import.meta.env
      .VITE_S3_BUCKET_NAME_FOR_PUBLIC_DOCUMENTS,
    fullstory: {
      isEnabled: import.meta.env.VITE_ENABLE_FULLSTORY === 'true',
      orgId: '12G20J',
    },
    magicBell: {
      isEnabled: import.meta.env.VITE_ENABLE_MAGIC_BELL === 'true',
      apiKey: import.meta.env.VITE_MAGIC_BELL_API_KEY,
    },
    featureFlags: {
      // Can stay empty, add feature flags here to avoid cluttering the regular config space
      // example:
      // audiences: import.meta.env.VITE_FEATURE_AUDIENCES === 'true',
      forceShowNativeDashboards:
        import.meta.env.VITE_FEATURE_FORCE_SHOW_NATIVE_DASH === 'true',
      // This flag is off on undefined/empty
      // It's on if any string with length > 0 is passed.
    },
    dap: {
      learnMoreUrl:
        import.meta.env.VITE_DAP_LEARN_MORE_URL || 'https://kortx.io',
      dynamicEmbedNameFilter:
        import.meta.env.VITE_FEATURE_DYNAMIC_EMBED_NAME_FILTER || '',
      dashboardFilterPresetVersion:
        import.meta.env.VITE_DAP_FILTER_PRESET_VERSION || 'v1',
    },
    tex: {
      chatUrl: import.meta.env.VITE_TEX_CHAT_URL || 'https://kortx.io',
    },
  };
};

const config = initConfig();

export default config;
