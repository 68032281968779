import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getInvoicesMetrics } from 'features/invoice/async';
import {
  advertiserIdsWithAccessForUserSelector,
  hasRoleWithAccessToAllAdvertisersSelector,
} from 'features/user/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

function WithInvoiceMetrics() {
  const dispatch = useAppDispatch();

  const { clientId: clientIdAsParam } = useParams();

  const hasAccessToAllAdvertisers = useAppSelector(
    hasRoleWithAccessToAllAdvertisersSelector
  );
  const advertiserIds = useAppSelector(advertiserIdsWithAccessForUserSelector);

  useEffect(() => {
    if (clientIdAsParam && hasAccessToAllAdvertisers) {
      const clientId = parseInt(clientIdAsParam, 10);

      dispatch(getInvoicesMetrics({ clientId: clientId }));
    }
  }, [clientIdAsParam, hasAccessToAllAdvertisers, dispatch]);

  useEffect(() => {
    if (clientIdAsParam && !hasAccessToAllAdvertisers) {
      const hasAtLeastOneAdvertiserWithAccess =
        advertiserIds !== undefined && advertiserIds.length > 0;

      if (hasAtLeastOneAdvertiserWithAccess) {
        const clientId = parseInt(clientIdAsParam, 10);

        dispatch(
          getInvoicesMetrics({
            clientId: clientId,
            advertiserIds: advertiserIds,
          })
        );
      }
    }
  }, [clientIdAsParam, advertiserIds, hasAccessToAllAdvertisers, dispatch]);

  return <></>;
}

export default WithInvoiceMetrics;
