/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';

export type RequestState = {
  id: string | undefined;
  isLoading: boolean | undefined;
  hasError: boolean;
};

export const initialRequestState: RequestState = {
  id: undefined,
  isLoading: undefined,
  hasError: false,
};

const requestSlice = createSlice({
  name: 'request',
  initialState: initialRequestState,
  reducers: {
    fulfilled(state, { payload: { meta } }) {
      if (state.isLoading && state.id === meta.requestId) {
        state.id = undefined;
        state.isLoading = false;
        state.hasError = false;
      }
    },
    pending(state, { payload: { meta } }) {
      state.id = meta.requestId;
      state.isLoading = true;
      state.hasError = false;
    },
    rejected(state, { payload: { meta } }) {
      if (state.isLoading && state.id === meta.requestId) {
        state.id = undefined;
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },
});

export const { fulfilled, pending, rejected } = requestSlice.actions;

export const requestActionsByType = {
  fulfilled,
  pending,
  rejected,
};

export const { reducer: requestReducer } = requestSlice;
